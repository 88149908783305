import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { api } from "helpers";

function List() {
  // init
  const navigate = useNavigate();
  const [getFilterData, setFilterData] = useState({});
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("apr")) {
      navigate("/profile");
    }
  }, []);
  // function
  const callAction = (data) => {
    if (data.type == "name" || data.type == "View") {
      navigate("/application/view/" + data.item._id);
    }
    if (data.type == "Export" || data.type == "export") {
      exportRecord();
    }
  };

  //api
  const exportRecord = () => {
    let data = {
      url: "application_export",
      method: "GET",
      query: `?query=${JSON.stringify({ ...getFilterData })}`,
      responseType: "file",
      fileName: "App_fee_report",
    };
    api.call(data, (res) => {});
  };

  return (
    permission.includes("apr") && (
      <AgTable
        module="adminApplication"
        filterData={{
          courseMulti: { key: "courseId", value: [] },
          agentMulti: { key: "agentId", value: [] },
          studentMulti: { key: "studentId", value: [] },
          subAgentMulti: {key: "subAgentId", value: []},
          paymentStatus: { key: "applicationFees", value: "" },
          paymentProcess: { key: "paidType", value: "" },
          applicationSelect: { key: "currentStatus", value: "" },
          registerFrom: { key: "registeredFrom", value: "", title: "Registered From" },
          universityMulti: { key: "university", value: [] },
          dateA: { key: "startDate", value: "", title: "Created Date From" },
          dateB: { key: "endDate", value: "", title: "Created Date To" },
        }}
        actionPermission={common.accessTable(permission, [
          "apc",
          "apu",
          "apr",
          "apd",
        ])}
        onAction={(data) => callAction(data)}
        onGetFilterQuery={setFilterData}
      />
    )
  );
}

export default List;
