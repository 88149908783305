import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
// includes
import AgTable from "elements/AgTable";
import { api, common, types } from "helpers";
import { useNavigate } from "react-router-dom";

function SubjectList() {
  // props
  const navigate = useNavigate();
  // state
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    id: "",
    name: "",
    isActive: true,
  });
  const [reload, setReload] = useState(new Date());
  const [myModal, setMyModal] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("sur")) {
      navigate("/profile");
    }
  }, []);
  // functions
  const closeModal = () => {
    setFields({
      id: "",
      name: "",
      isActive: true,
    });
    setSubmit(false);
    setLoading(false);
    setMyModal(null);
  };

  // handler
  const onInputChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      setError("Subject is required");
    } else {
      setError("");
    }
    setFields((prev) => ({ ...prev, name: value }));
  };

  const onSubmit = () => {
    setSubmit(true);
    const { id } = fields;
    if (fields.name === "") {
      setError("Subject is required");
    } else {
      setLoading(true);
      let data = {};
      delete fields.id;
      if (id) {
        data = {
          url: "subject",
          method: "PUT",
          query: `/${id}`,
          body: fields,
        };
      } else {
        data = {
          url: "subject",
          method: "POST",
          body: fields,
        };
      }
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify(
            "S",
            `Subject has been ${fields.id ? "created" : "updated"}`
          );
        } else {
          common.notify("E", "Something went wrong");
        }
        closeModal();
        setReload(new Date());
      });
    }
  };

  // callback
  const callAction = (data) => {
    const { type, item } = data;
    if (type == "Edit") {
      setFields({
        id: item._id,
        name: item.name,
        isActive: item.isActive,
      });
    }
    setMyModal("subject");
  };

  return (
    permission.includes("sur") && (
      <Fragment>
        <AgTable
          module="adminSubject"
          filterData={{}}
          onAction={(data) => callAction(data)}
          reload={reload}
          actionPermission={common.accessTable(permission, [
            "suc",
            "suu",
            "sur",
            "sud",
          ])}
        />
        <Modal
          isOpen={myModal === "subject"}
          style={common.modalStyle(70)}
          ariaHideApp={false}
          contentLabel="Modal"
        >
          <div className="container-fluid">
            <div className="row modal-header">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <p className="modal-title mb-0">
                  {!fields.id ? "Add" : "Edit"} Subject
                </p>
                <img
                  src={common.loadImg("close-icn.svg")}
                  alt="arrow"
                  onClick={closeModal}
                  className="ed-cp img-fluid"
                />
              </div>
            </div>
            <div className="row modal-body">
              <div className="form-wrap">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <label>Subject Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={fields.name}
                      onChange={onInputChange}
                    />
                    {error && submit && <p className="error-txt">{error}</p>}
                  </div>

                  <div className="col-md-6">
                    <label>Status</label>
                    <Select
                      value={types.status[fields.isActive ? 0 : 1]}
                      options={types.status}
                      className="custom-select-box"
                      onChange={(data) =>
                        setFields((prev) => ({
                          ...prev,
                          isActive: Boolean(data.value),
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn ed-btn-default ed-btn-save-dt"
                disabled={loading}
                onClick={onSubmit}
              >
                {loading ? "Processing" : "Save & Update"}
              </button>
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  );
}

export default SubjectList;
