import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ModalInfo(props) {
  return (
    <Modal show={true} onHide={props?.onYes} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>{props.data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.data.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props?.onYes}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
  );
}
export default ModalInfo;
