import { React, useState, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
//includes
import { common, api, types } from "helpers";
import StatusBar from "elements/statusBar";
import Select from "react-select";

function View() {
  // props
  let { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState([]);
  const [cashStatus, setCashStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [isNote, setIsNote] = useState(false);
  const [permission] = useState(common.getUserPermission());

  // effect
  useEffect(() => {
    getCashbackInfo();
  }, []);

  // api
  const getCashbackInfo = () => {
    let data = {
      url: "cashbackRequest",
      query: `/${id}`,
      method: "GET",
    };
    api.call(data, (res) => {
      let apiStatus = "error";
      if (res.status === 200) {
        if (res?.data?.length > 0) {
          setResult(res.data[0]);
          types.cashbackReqStatus.map((item) => {
            if (res.data[0].status == item.value) {
              setCashStatus(item);
            }
          });
          if (res.data[0].status == "Rejected") {
            setIsNote(true);
          }
        } else {
          setResult(res.data);
        }
        apiStatus = "success";
      }
      setStatus(apiStatus);
    });
  };

  const changeStatus = (data) => {
    if (data && data.value) {
      setCashStatus(data);
      result.status = data.value;
      setResult({ ...result });
      if (data.value == "Rejected") {
        setIsNote(true);
      }
    } else {
      setCashStatus(data);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    let data = {};
    if (id) {
      data = {
        url: "cashbackRequest",
        method: "PUT",
        query: `/${id}`,
        body: result,
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", `updated successfully`);
        } else {
          if (res?.data?.error) {
            common.notify("E", res?.data?.error);
          } else {
            common.notify("E", "Something went wrong");
          }
        }
        setLoading(false);
      });
    }
  };

  const handleChange = (e) => {
    result.note = e.target.value;
    setResult({ ...result });
  };

  //render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        {permission.includes("cqu") &&
          <div className="cashback-req-header">
            <h1 className="page-title ps-4">Cashback Request View</h1>
            <button
              className="btn ed-btn-default ed-btn-save-dt"
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? "Processing..." : "Update"}
            </button>
          </div>
        }
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <div className="et-vc-info et-view-border-bottom">
                <ul className="et-vc-list row">
                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-value">
                        {result.studentId?.firstName}{" "}
                        {result.studentId?.lastName}
                      </p>
                      <p className="et-field-name">Profile</p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Status</p>
                      <Select
                        className="custom-select-box"
                        value={cashStatus}
                        options={types.cashbackReqStatus}
                        isClearable={true}
                        onChange={(data) => {
                          changeStatus(data);
                        }}
                        isDisabled={ cashStatus == null || cashStatus?.value === "Requested" ? false : true}
                      />
                      {/* <p className="et-field-value">{result.status}</p> */}
                    </div>
                  </li>
                  {isNote && (
                    <li
                      className={`col-md-4 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Note</p>
                        <input
                          type="text"
                          name="note"
                          placeholder="Enter the note"
                          className="form-control"
                          value={result.note}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              <div class="et-view-title-wrap d-flex justify-content-between align-items-center">
                <h3 class="et-view-secondary-title">Student Info</h3>
              </div>
              <div className="et-vc-info et-view-border-bottom">
                <ul className="et-vc-list row">
                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Student Name</p>
                      <p className="et-field-value">
                        {result.studentId?.firstName}{" "}
                        {result.studentId?.lastName}
                      </p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Date</p>
                      <p className="et-field-value">
                        {common.readable("date", result.createAt)}
                      </p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Request For</p>
                      <p className="et-field-value">{result.requestFor}</p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Amount</p>
                      <p className="et-field-value">{result.amount}</p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Description</p>
                      <p className="et-field-value">{result.description}</p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Proof of purchase</p>
                      {result.proofDocs?.length > 0 &&
                        result.proofDocs?.map((item) => {
                          return (
                            <div className="row mb-3">
                              <div className="et-field-value col-md-9">
                                <span
                                  className="et-link"
                                  onClick={() => common.openCloudUrl(item)}
                                >
                                  {common.fileName(item)}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </li>
                </ul>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
export default View;
