import { useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import { common } from "helpers";

function Profile() {
  // init
  const navigate = useNavigate();
  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex justify-content-between align-items-center">
        <h1 className="page-title">Profile</h1>
      </div>
      <div className="content-wrap form-page">
        <p>Profile</p>
      </div>
    </Fragment>
  );
}

export default Profile;
