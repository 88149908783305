import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { api } from "helpers";
import ModalConfirm from "elements/modalConfirm";

function SkillList() {
  // init
  const navigate = useNavigate();
  // state
  const [permission] = useState(common.getUserPermission());
  const [deleteData, setDeleteData] = useState(null);
  const [loading, setLoading] = useState(false);
  // effect
  useEffect(() => {
    if (!permission.includes("jsr")) {
      navigate("/profile");
    }
  }, []);
  // functions
  const callAction = (data) => {
    if (data.type == "Add" || data.type == "add") {
      navigate("/jobskill/add");
    } else if (data.type == "Edit") {
      navigate("/jobskill/edit/" + data.item._id);
    } else if (data.type == "View") {
      navigate("/jobskill/view/" + data.item._id);
    } else if (data.type == "Delete") {
      setDeleteData(data.item);
    }
  };
  //api
  const DeleteRecord = (item) => {
    setLoading(true);
    let data = {
      url: "jobSkill",
      method: "DELETE",
      query: `/${item._id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Record has been removed");
        setDeleteData(null);
        setLoading(false);
      }
    });
  };
  return (
    permission.includes("jsr") && (
      <>
        <AgTable
          module="jobSkill"
          filterData={{
            status: { key: "isActive", value: "" },
          }}
          onAction={(data) => callAction(data)}
          actionPermission={common.accessTable(permission, [
            "jsc",
            "jsu",
            "jsr",
            "jsd",
          ])}
        />
        {deleteData !== null && (
          <ModalConfirm
            loading={loading}
            onYes={() => DeleteRecord(deleteData)}
            onNo={() => setDeleteData(null)}
          />
        )}
      </>
    )
  );
}

export default SkillList;
