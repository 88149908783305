import { api, common, types } from "helpers";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import StatusBar from "elements/statusBar";

function Home() {
  // init
  const navigate = useNavigate();
  // state
  const [permission] = useState(common.getUserPermission());
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState({});
  // effect
  useEffect(() => {
    if (!permission?.includes("hor")) {
      navigate("/profile");
    } else {
      getInfo();
    }
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "dashboard",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        setResult(result);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  // render
  return (
    permission?.includes("hor") && (
      <Fragment>
        <div className="title-wrap d-flex justify-content-between align-items-center">
          <h1 className="page-title">Dashboard</h1>
        </div>
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <div className="content-wrap form-page">
            <div className="row">
              {types.dashboard.map((i, k) => (
                <div className="col-md-4 mb-4" key={k}>
                  <div className="card">
                    <div className="d-flex justify-content-between align-items-center px-4 py-4">
                      <p className="de-name mb-0">{i.name}</p>
                      <p className="de-count mb-0">{result[i.key]}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Fragment>
    )
  );
}

export default Home;
