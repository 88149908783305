import ModalConfirm from "elements/modalConfirm";
import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { api } from "helpers";

function CashbackList() {
  // init
  const navigate = useNavigate();
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("car")) {
      navigate("/profile");
    }
  }, []);
  // function
  const callAction = (data) => {
    if (data.type == "View") {
      navigate("/cashback/view/" + data.item._id);
    }
  };

  return (
    permission.includes("car") && (
      <AgTable
        module="adminCashback"
        filterData={{
          courseSingle: { key: "course", value: "" },
          studentSingle: { key: "studentId", value: "" },
          universitySingle: { key: "university", value: "" },
          dateA: { key: "startDate", value: "", title: "Enrolled Date From" }
        }}
        onAction={(data) => callAction(data)}
        actionPermission={common.accessTable(permission, [
          "cac",
          "cau",
          "car",
          "cad",
        ])}
      />
    )
  );
}

export default CashbackList;
