import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";

function UniversityList() {
  // init
  const navigate = useNavigate();
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("pcr")) {
      navigate("/profile");
    }
  }, []);
  // functions
  const callAction = (data) => {
    if (data.type == "Add" || data.type == "add") {
      navigate("/promo/add");
    } else if (data.type == "Edit") {
      navigate("/promo/edit/" + data.item._id);
    } else if (data.type == "View") {
      navigate("/promo/edit/" + data.item._id);
    }
  };

  return (
    permission.includes("pcr") && (
      <AgTable
        module="adminPromoCode"
        filterData={{
          status: { key: "isActive", value: "" },
          dateA: { key: "startDate", value: "", title: "Promo Start Date" },
          dateB: { key: "endDate", value: "", title: "Promo End Date" },
        }}
        onAction={(data) => callAction(data)}
        actionPermission={common.accessTable(permission, [
          "pcc",
          "pcu",
          "pcr",
          "pcd",
        ])}
      />
    )
  );
}

export default UniversityList;
