import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
// includes
import { api, common, types } from "helpers";
import StatusBar from "elements/statusBar";
import Select from "react-select";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState("");
  const [permission, setPermission] = useState([]);
  // validate
  const formFields = {
    name: { validate: ["req"] },
    isActive: { value: types.status[0] }
  };
  const { fields, errors, handleChange, handleSubmit, setMultiValue } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "permission",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        setMultiValue({
          name: result.name,
          isActive: result.isActive ? types.status[0] : types.status[1],
        });
        setPermission(result.permissions);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  // handler
  const handleCheckChange = (e) => {
    const { checked, value } = e.target;
    setPermission((prev) => {
      let nData = [...prev];
      checked ? nData.push(value) : nData.splice(nData.indexOf(value), 1);
      return nData;
    });
  };

  const onSubmit = () => {
    let valid = handleSubmit();
    if (valid) {
      setLoading(true);
      let data = {};
      let params = { 
        name : fields.name, 
        isActive: fields.isActive.value
      };
      if (id) {
        delete params.password;
        data = {
          url: "permission",
          method: "PUT",
          query: `/${id}`,
          body: { ...params, permissions: permission,},
        };
      } else {
        data = {
          url: "permission",
          method: "POST",
          body: { ...params, permissions: permission },
        };
      }
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify(
            "S",
            `Permission has been ${!id ? "created" : "updated"} successfully`
          );
          navigate("/permissions");
        } else if (res.status === 500) {
          setError("Email address already exist");
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={common.loadImg("back-icn.svg")}
            className="ed-cp"
            alt=""
            onClick={() => navigate("/permissions")}
          />
          <h1 className="page-title ps-4">{id ? "Edit" : "Add New"} Permission</h1>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <div className="container-fluid">
            <h2 className="form-title">Info</h2>
            <div className="form-wrap">
              <div className="row pb-4 ed-bb">
                <div className="col-md-6 mb-4">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter the name"
                    className="form-control"
                    value={fields.name}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.name}</p>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Status</label>
                  <Select
                    className="custom-select-box"
                    value={fields.isActive}
                    options={types.status}
                    isClearable={false}
                    onChange={(data) => {
                      setMultiValue({ isActive: data });
                    }}
                  />
                </div>
              </div>
              <p className="pt-3">Permissions</p>
              {types.permission.list.map((i, k) => (
                <div key={k}>
                  <label>{types.permission.label[i.key]}</label>
                  <ul className="row permissions-wrap pt-2">
                    {i.types.map((m, n) => (
                      <li className="col-md-4" key={n}>
                        <label className="role-checkbox-wrap">
                          <input
                            type="checkbox"
                            name="read"
                            value={i.key + m}
                            checked={permission?.includes(i.key + m)}
                            onChange={handleCheckChange}
                          />
                          <span className="checkmark"></span>
                          {types.permission.label[m]}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                className="btn ed-btn-default ed-btn-save-dt"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Save & Update"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default Form;
