import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
// includes
import { api, common, config, types } from "helpers";
import StatusBar from "elements/statusBar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Select from "react-select";
import FileUpload from "elements/fileUpload";
import { Link } from "@mui/material";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState("");
  const [permission, setPermission] = useState(common.getPermission());
  // validate
  const formFields = {
    firstName: { validate: ["req"] },
    lastName: {},
    email: { validate: ["req", "email"] },
    password: {
      validate: ["req", "min:8", "max:15"],
      value: common.generateString(8),
    },
    // dob: {},
    // gender: {},
    // passportNumber: {},
    // nationality: {},
    // firstLanguage: {},
    // maritalStatus: {},
    // addressWithRegion: {},
    // country: {},
    // province: {},
    // city: {},
    // postalCode: {},
    // mobile: {},
    // mobileCode: {},
    // countryOfEducation: {},
    // highLevelOfEducation: {},
    // grade: {},
    // college: {},
    // exam: {},
    // isVisaBeforeECA: {},
    // isVisaRejectedECA: {},
    // workExp: {},
    // passportDocs: {},
    // visaDocs: {},
    // otherActivity: {},
    // extraCurricular: {}
  };
  const { fields, errors, setValue, handleChange, handleSubmit, setMultiValue } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "student",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        setMultiValue({
          email: result.email,
          password: common.generateString(8),
          firstName: result.firstName,
          lastName: result.lastName,
        });
        setPermission(result.permissions);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  // handler
  const handleCheckChange = (e) => {
    const { checked, value } = e.target;
    setPermission((prev) => {
      let nData = [...prev];
      checked ? nData.push(value) : nData.splice(nData.indexOf(value), 1);
      return nData;
    });
  };

  const onSubmit = () => {
    let valid = handleSubmit();
    if (valid) {
      setLoading(true);
      let data = {};
      let params = { ...fields };
      if (id) {
        delete params.password;
        data = {
          url: "student",
          method: "PUT",
          query: `/${id}`,
          body: { ...params, permissions: permission },
        };
      } else {
        data = {
          url: "student",
          method: "POST",
          body: { ...params, permissions: permission },
        };
      }
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify(
            "S",
            `Student has been ${!id ? "created" : "updated"} successfully`
          );
          navigate("/student");
        } else if (res.status === 500) {
          setError("Email address already exist");
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={common.loadImg("back-icn.svg")}
            className="ed-cp"
            alt=""
            onClick={() => navigate("/student")}
          />
          <h1 className="page-title ps-4">{id ? "Edit" : "Add New"} Student</h1>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <>
            <div className="container-fluid">
              <h2 className="form-title">General Information</h2>
              <div className="form-wrap">
                <div className="row pb-4 ed-bb">
                  <div className="col-md-6 mb-4">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Enter the first name"
                      className="form-control"
                      value={fields.firstName}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.firstName}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Enter the last name"
                      className="form-control"
                      value={fields.lastName}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.lastName}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Email Address</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter the email"
                      className="form-control"
                      value={fields.email}
                      onChange={(e) => {
                        handleChange(e);
                        setError("");
                      }}
                    />
                    <p className="error-txt">{errors.email || error}</p>
                  </div>
                  {!id && (
                    <div className="col-md-6 mb-4">
                      <label>Password</label>
                      <input
                        type="text"
                        name="password"
                        placeholder="Enter the password"
                        className="form-control"
                        value={fields.password}
                        onChange={handleChange}
                      />
                      <p className="error-txt">{errors.password}</p>
                    </div>
                  )}
                  {/* <div className="col-md-6 mb-4 date-picker">
                    <label>DOB</label>
                    <div>
                      <DateRangePicker
                        initialSettings={{
                          ...config.elements.singleDp,
                        }}
                        onShow={(event, picker) => {
                          setValue("dob", moment(picker.startDate).format(
                            config.elements.dp.A
                          ));
                        }}
                      >
                        <input
                          type="text"
                          className="form-control date-picker"
                          value={fields.dob}
                          placeholder="Select the DOB"
                          readOnly={true}
                        />
                      </DateRangePicker>
                      <button
                        className="clear-icn"
                        onClick={() => {
                          setValue("dob", "");
                        }}
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          className="css-tj5bde-Svg"
                        >
                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Gender</label>
                    <div>
                      <Select
                        className="custom-select-box"
                        value={fields.gender}
                        options={types.gender}
                        isClearable={true}
                        onChange={(data) => {
                          setValue("gender", data);
                        }}
                      />
                    </div>
                    <p className="error-txt">{errors.gender || error}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Marital Status</label>
                    <div>
                      <Select
                        className="custom-select-box"
                        value={fields.maritalStatus}
                        options={types.maritalStatus}
                        isClearable={true}
                        onChange={(data) => {
                          setValue("maritalStatus", data);
                        }}
                      />
                    </div>
                    <p className="error-txt">{errors.maritalStatus || error}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>First Language</label>
                    <input
                      type="text"
                      name="firstLanguage"
                      placeholder="Enter the First Language"
                      className="form-control"
                      value={fields.firstLanguage}
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="error-txt">{errors.firstLanguage || error}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Passport Number</label>
                    <input
                      type="text"
                      name="passportNumber"
                      placeholder="Enter the email"
                      className="form-control"
                      value={fields.passportNumber}
                      onChange={(e) => handleChange}
                    />
                    <p className="error-txt">{errors.passportNumber || error}</p>
                  </div>
                  <div className="col-12 mb-4">
                    <label>Passport Document</label>
                    <FileUpload
                      multiple={true}
                      onSuccess={(files) => {
                        console.log(files);
                        setValue("passportDocument", files.acceptedFiles);
                      }
                      }
                    />
                    {fields.passportDocument?.length > 0 && fields.passportDocument.map((file, index) => (
                      <div
                        className="d-flex align-items-center justify-content-between pt-3"
                        key={index}
                      >
                        <p
                          className="agt-proof-file mb-0"
                          onClick={() => common.openObjectUrl(file)}
                        >
                          <Link to="">
                            {file.name}
                          </Link>
                        </p>
                        <img
                          src={common.loadImg("iconly-dark-delete.svg")}
                          className="et-cp"
                          onClick={() => {
                            setValue("passportDocument", fields.passportDocument.filter((i, k) => k !== index));
                          }}
                        />
                      </div>
                    ))}
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="container-fluid">
              <h2 className="form-title">Address Details</h2>
              <div className="form-wrap">
                <div className="row pb-4 ed-bb">
                  <div className="col-md-12">
                    <label>Address With Region</label>
                    <input
                      type="text"
                      name="addressWithRegion"
                      placeholder="Enter the Address"
                      className="form-control"
                      value={fields.addressWithRegion}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.addressWithRegion}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Province</label>
                    <input
                      type="text"
                      name="province"
                      placeholder="Enter the province"
                      className="form-control"
                      value={fields.province}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.province}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>City / Town</label>
                    <input
                      type="text"
                      name="city"
                      placeholder="Enter the city"
                      className="form-control"
                      value={fields.city}
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="error-txt">{errors.city || error}</p>
                  </div>
                  {!id && (
                    <div className="col-md-6 mb-4">
                      <label>Country</label>
                      <Select
                        className="custom-select-box"
                        value={fields.nationality}
                        options={types.country}
                        isClearable={true}
                        onChange={(data) => {
                          setValue("nationality", data);
                        }}
                      />
                      <p className="error-txt">{errors.country}</p>
                    </div>
                  )}
                  <div className="col-md-6 mb-4 date-picker">
                    <label>Postal Code</label>
                    <input
                      type="text"
                      name="postalCode"
                      placeholder="Enter the postal code"
                      className="form-control"
                      value={fields.postalCode}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.postalCode}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <h2 className="form-title">Education History - General</h2>
              <div className="form-wrap">
                <div className="row pb-4 ed-bb">
                  <div className="col-md-6 mb-4">
                    <label>Country of education</label>
                    <Select
                      className="custom-select-box"
                      value={fields.countryOfEducation}
                      options={types.country}
                      isClearable={true}
                      onChange={(data) => {
                        setValue("countryOfEducation", data);
                      }}
                    />
                    <p className="error-txt">{errors.countryOfEducation || error}</p>
                  </div>
                  {!id && (
                    <div className="col-md-6 mb-4">
                      <label>Highest level of education</label>
                      <Select
                        className="custom-select-box"
                        value={fields.highLevelOfEducation}
                        options={types.educationLevel}
                        isClearable={true}
                        onChange={(data) => {
                          setValue("educationLevel", data);
                        }}
                      />
                      <p className="error-txt">{errors.highLevelOfEducation}</p>
                    </div>
                  )}
                  <div className="col-md-6 mb-4 date-picker">
                    <label>Grade</label>
                    <input
                      type="text"
                      name="grade"
                      placeholder="Enter the grade"
                      className="form-control"
                      value={fields.grade}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.grade}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <h2 className="form-title">Background Information</h2>
              <div className="form-wrap">
                <div className="row pb-4 ed-bb align-items-center d-flex">
                  <div className="col-md-6 mt-2">
                    <label>Did you apply for a visa before as a part of your entry clearance application?</label>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="bgbutton">
                        <input
                          type="radio"
                          id="a25"
                          name="check-substitution-2"
                          className="form-control"
                          checked={fields.isVisaBeforeECA === "Yes"}
                          onChange={() => {
                            setValue("isVisaBeforeECA", "Yes")
                          }

                          }
                        />
                        <label className="btn btn-default" htmlFor="a25">
                          Yes
                        </label>
                      </div>
                      <div className="bgbutton margino">
                        <input
                          type="radio"
                          id="a50"
                          name="check-substitution-2"
                          className="form-control"
                          checked={fields.isVisaBeforeECA === "No"}
                          onChange={() => {
                            setValue("isVisaBeforeECA", "No")
                          }

                          }
                        />
                        <label className="btn btn-default" htmlFor="a50">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pb-4 ed-bb align-items-center d-flex">
                  <div className="col-md-6 mt-2">
                    <label>Did your visa have been rejected before as a part of your entry clearance application?
                    </label>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="bgbutton">
                        <input
                          type="radio"
                          id="a25"
                          name="check-substitution-2"
                          className="form-control"
                          checked={fields.isVisaRejectedECA === "Yes"}
                          onChange={() => {
                            setValue("isVisaRejectedECA", "Yes")
                          }

                          }
                        />
                        <label className="btn btn-default" htmlFor="a25">
                          Yes
                        </label>
                      </div>
                      <div className="bgbutton margino">
                        <input
                          type="radio"
                          id="a50"
                          name="check-substitution-2"
                          className="form-control"
                          checked={fields.isVisaRejectedECA === "No"}
                          onChange={() => {
                            setValue("isVisaRejectedECA", "No")
                          }

                          }
                        />
                        <label className="btn btn-default" htmlFor="a50">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="container-fluid">
              <div className="modal-footer">
                <button
                  className="btn ed-btn-default ed-btn-save-dt"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Save & Update"}
                </button>
              </div>
            </div>
          </>

        )}
      </div>
    </Fragment>
  );
}
export default Form;
