import { forwardRef, useEffect, useState } from "react";
import Select from "react-select";
// includes
import { api, common } from "helpers";

const FieldSelect = forwardRef((props, ref) => {
  // props
  const { render, placeholder, clearable, finder, size, auth } = props;
  // state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // effect
  useEffect(() => {
    if (render === "empty") {
      setData([]);
    } else if (render !== "no") {
      getList();
    }
  }, [render]);

  // api
  const getList = () => {
    setData([]);
    setLoading(true);
    let data = {
      url: props.api,
      method: "GET",
      auth: auth || "token",
      query: props.query,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let selectData = common.SetMultiSelect(
          res.data.data,
          props.get,
          props.set,
          props.join
        );
        setData(selectData);
        if (finder) {
          if (selectData && props.value) {
            if (typeof props.value === "object") {
              props?.onSelect(common.FindMultiSelect(selectData, props.value));
            } else {
              props?.onSelect(common.FindSelect(selectData, props?.value));
            }
          }
        }
      }
      setLoading(false);
    });
  };
  // handler
  const onChange = (value) => {
    props.onSelect(value);
  };

  return (
    <Select
      defaultValue={[]}
      isMulti={props.multi}
      value={props.finder ? null : props.value}
      options={data}
      className={size === "small" ? "basic-multi-select" : "custom-select-box"}
      isClearable={clearable}
      isLoading={loading}
      isDisabled={props.disabled}
      placeholder={loading ? "Loading data please wait..." : placeholder}
      onChange={(data) => onChange(data)}
    />
  );
});

export default FieldSelect;
