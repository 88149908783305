import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";

function UniversityList() {
  // init
  const navigate = useNavigate();
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("usr")) {
      navigate("/profile");
    }
  }, []);
  // functions
  const callAction = (data) => {
    if (data.type == "Add" || data.type == "add") {
      navigate("/users/add");
    } else if (data.type == "Edit") {
      navigate("/users/edit/" + data.item._id);
    } else if (data.type == "View") {
      navigate("/users/edit/" + data.item._id);
    }
  };

  return (
    permission.includes("usr") && (
      <AgTable
        module="adminUsers"
        filterData={{
          status: { key: "isActive", value: "" },
          dateA: { key: "startDate", value: "", title: "Created Date From" },
          dateB: { key: "endDate", value: "", title: "Created Date To" },
        }}
        onAction={(data) => callAction(data)}
        actionPermission={common.accessTable(permission, [
          "usc",
          "usu",
          "usr",
          "usd",
        ])}
      />
    )
  );
}

export default UniversityList;
