import { api, common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";

function List() {
  // init
  const navigate = useNavigate();
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("agr")) {
      navigate("/profile");
    }
  }, []);
  // function
  const callAction = (data) => {
    if (data.type === "Add") {
      navigate("/agent/add");
    } else if (data.type == "View") {
      navigate("/agent/view/" + data.item._id);
    } else if (data.type == "Export" || data.type == "export") {
      exportRecord(data);
    }
  };

  const exportRecord = (val) => {
    let data = {
      url: "agent_export",
      method: "GET",
      query: val.query,
      responseType: "file",
      fileName: "agent",
    };
    api.call(data, (res) => {});
  };

  return (
    permission.includes("agr") && (
      <AgTable
        module="adminAgent"
        filterData={{
          promotorMulti: { key: "promotor", value: ""},
          planMulti: { key: "planId", value: "" },
          status: { key: "isActive", value: "" },
          verified: { key: "isVerified", value: "" },
          promotor: { key: "isPromotor", value: "" },
          dateA: { key: "startDate", value: "", title: "Created Date From" },
          dateB: { key: "endDate", value: "", title: "Created Date To" },
        }}
        onAction={(data) => callAction(data)}
        actionPermission={common.accessTable(permission, [
          "agc",
          "agu",
          "agr",
          "agd",
        ])}
      />
    )
  );
}

export default List;
