import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// includes
import { api, common, config } from "helpers";
import StatusBar from "elements/statusBar";

function View() {
  const { id } = useParams();
  const navigate = useNavigate();
  const htmlParse = require("html-react-parser");
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState(null);

  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "blogContent",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setResult(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4">Blog Content View</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <div className="et-view-content-wrap">
                {/* Inner Content */}
                <div className="et-vc-info et-view-border-bottom">
                  <ul className="et-vc-list row">
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Title</p>
                        <p className="et-field-value">
                          {result?.title ? result?.title : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Image</p>
                        <p className="et-field-value">
                          {/* {result?.image ? result?.image: "-"} */}
                          <img
                            src={config.api.cloudImgPath + result?.image}
                            alt="logo"
                            width="80"
                            height="80"
                          />
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Short Description</p>
                        <p className="et-field-value">
                          {result?.shortDescription
                            ? result?.shortDescription
                            : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      {/* <div className="et-list-inner">
                        <p className="et-field-name">Description</p>
                        <p className="et-field-value">
                          {result?.description
                            ? htmlParse(result.description)
                            : "-"}
                        </p>
                      </div> */}
                    </li>
                    <h2 className="form-title-step3">Description</h2>
                    <div className="row-wrap-step3 editor-content-wrap et-view-border-bottom ">
                      {result?.description
                        ? htmlParse(result.description)
                        : "-"}
                    </div>

                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Category</p>
                        <p className="et-field-value">
                          {result?.categoryId ? result?.categoryId?.name : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Author Name</p>
                        <p className="et-field-value">
                          {result?.authorName ? result?.authorName : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Meta Key</p>
                        <p className="et-field-value">
                          {result?.metaKey ? result?.metaKey : "-"}
                        </p>
                      </div>
                    </li>

                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Meta Title</p>
                        <p className="et-field-value">
                          {result?.metaTitle ? result?.metaTitle : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Meta Description</p>
                        <p className="et-field-value">
                          {result?.metaDescription
                            ? result?.metaDescription
                            : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Active</p>
                        <p className="et-field-value">
                          {result?.isActive ? "Yes" : "No"}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
export default View;
