import { React, useState, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
//includes
import { common, api, types } from "helpers";
import StatusBar from "elements/statusBar";
// elements
import moment from "moment";
import Switch from "@mui/material/Switch";
import Select from "react-select";

function View() {
  // props
  let { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(null);
  const [cashStatus, setCashStatus] = useState({});
  const [permission] = useState(common.getUserPermission());

  // effect
  useEffect(() => {
    getCashbackInfo();
  }, []);

  // api
  const getCashbackInfo = () => {
    let data = {
      url: "cashback",
      query: `/${id}`,
      method: "GET",
    };
    api.call(data, (res) => {
      let apiStatus = "error";
      if (res.status === 200) {
        if (res?.data?.length > 0) {
          setResult(res.data[0]);
          types.cashbackStatus.map((item) => {
            if (res.data[0].status == item.value) {
              setCashStatus(item);
            }
          });
        } else {
          setResult(res.data);
        }
        apiStatus = "success";
      }
      setStatus(apiStatus);
    });
  };

  const handleChange = (e, key, type) => {
    result.cashBackSplitup[key][e.target.name] =
      type == "input" ? e.target.value : e.target.checked;
    setResult({ ...result });
  };

  const onSubmit = () => {
    setLoading(true);
    let data = {};
    if (id) {
      data = {
        url: "cashback",
        method: "PUT",
        query: `/${id}`,
        body: result,
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", `updated successfully`);
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  const changeStatus = (data) => {
    if (data && data.value) {
      setCashStatus(data);
      result.status = data.value;
      setResult({ ...result });
      onSubmit();
    } else {
      setCashStatus(data);
    }
  };

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  };

  //render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4">Cashback View</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <div className="et-vc-info et-view-border-bottom">
                <ul className="et-vc-list row">
                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">

                    <p className="et-field-name">Profile</p>
                      <p className="et-field-value">
                        {result.studentId?.firstName}{" "}
                        {result.studentId?.lastName}
                      </p>
                    </div>
                  </li>
                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Application Id</p>
                      <p className="et-field-value">
                        {common.readable("string", result.applicationRefId)}
                      </p>
                    </div>
                  </li>
                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Status</p>
                      <Select
                        className="custom-select-box"
                        value={cashStatus}
                        options={types.cashbackStatus}
                        isClearable={true}
                        onChange={(data) => {
                          changeStatus(data);
                        }}
                        isDisabled={permission.includes("cau") ? false : true}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div class="et-view-title-wrap d-flex justify-content-between align-items-center">
                <h3 class="et-view-secondary-title">Student Info</h3>
              </div>
              <div className="et-vc-info et-view-border-bottom">
                <ul className="et-vc-list row">
                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Student Name</p>
                      <p className="et-field-value">
                        {result.studentId?.firstName}{" "}
                        {result.studentId?.lastName}
                      </p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Course Name</p>
                      <p className="et-field-value">{result.course?.name}</p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">University Name</p>
                      <p className="et-field-value">
                        {result.course?.university?.name}
                      </p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Intake Month</p>
                      <p className="et-field-value">
                        {getMonthName(result.month)}
                      </p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Intake Year</p>
                      <p className="et-field-value">{result.year}</p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Course Duration</p>
                      <p className="et-field-value">{result.courseDuration}</p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Enrolled Date</p>
                      <p className="et-field-value">
                        {common.readable("dateTime", result.enrolledDate)}
                      </p>
                    </div>
                  </li>

                  <li
                    className={`col-md-4 et-view-border-bottom et-view-border-right`}
                  >
                    <div className="et-list-inner">
                      <p className="et-field-name">Eligible Cashback</p>
                      <p className="et-field-value">
                        {result.eligibleCashback}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              {result?.cashBackSplitup?.length > 0 && (
                <>
                  <div class="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 class="et-view-secondary-title">Cashback Split Up</h3>
                  </div>
                  <div className="container-fluid px-0">
                    <div className="modal-footer">
                      {/* <button
                        className="btn ed-btn-default ed-btn-save-dt"
                        onClick={onSubmit}
                        disabled={loading}
                      >
                        {loading ? "Processing..." : "Update"}
                      </button> */}
                      <table className="cashback">
                        <tr>
                          <th>Year - Split Up</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Amount Eligible</th>
                          <th>Approval</th>
                        </tr>
                        {result.cashBackSplitup.map((item, i) => {
                          var date = moment().format("YYYY-MM-DD");
                          var sDate = moment(item.startDate).format(
                            "YYYY-MM-DD"
                          );
                          var eDate = moment(item.endDate).format("YYYY-MM-DD");
                          return (
                            <tr key={i}>
                              <td>{item.idx}</td>
                              <td>
                                {moment(item.startDate).format("MMMM DD, YYYY")}
                              </td>
                              <td>
                                {moment(item.endDate).format("MMMM DD, YYYY")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="amount"
                                  placeholder="Enter the amount"
                                  className="form-control cashback-input"
                                  value={item.amount}
                                  onChange={(e) => handleChange(e, i, "input")}
                                />
                              </td>
                              <td>
                                <Switch
                                  defaultChecked={item.isApproved}
                                  color="secondary"
                                  name="isApproved"
                                  // onChange={(e) => handleChange(e, i, "switch")}
                                  // disabled={
                                  //   date >= sDate &&
                                  //   date <= eDate &&
                                  //   result.status == "Approved"
                                  //     ? false
                                  //     : true
                                  // }
                                  disabled={true}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
export default View;
