import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
// includes
import { api, common, config, types } from "helpers";
import StatusBar from "elements/statusBar";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState("");
  const [permission, setPermission] = useState(common.getPermission());
  // validate
  const formFields = {
    firstName: { validate: ["req"] },
    lastName: {},
    email: { validate: ["req", "email"] },
    password: {
      validate: ["req", "min:8", "max:15"],
      value: common.generateString(8),
    },
  };
  const { fields, errors, setValue, handleChange, handleSubmit, setMultiValue } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "agent",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        setMultiValue({
          email: result.email,
          password: common.generateString(8),
          firstName: result.firstName,
          lastName: result.lastName,
        });
        setPermission(result.permissions);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  // handler
  const handleCheckChange = (e) => {
    const { checked, value } = e.target;
    setPermission((prev) => {
      let nData = [...prev];
      checked ? nData.push(value) : nData.splice(nData.indexOf(value), 1);
      return nData;
    });
  };

  const onSubmit = () => {
    let valid = handleSubmit();
    if (valid) {
      setLoading(true);
      let data = {};
      let params = { ...fields };
      if (id) {
        delete params.password;
        data = {
          url: "agent",
          method: "PUT",
          query: `/${id}`,
          body: { ...params, permissions: permission },
        };
      } else {
        data = {
          url: "agent",
          method: "POST",
          body: { ...params, permissions: permission },
        };
      }
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify(
            "S",
            `Agent has been ${!id ? "created" : "updated"} successfully`
          );
          navigate("/agent");
        } else if (res.status === 500) {
          setError("Email address already exist");
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={common.loadImg("back-icn.svg")}
            className="ed-cp"
            alt=""
            onClick={() => navigate("/agent")}
          />
          <h1 className="page-title ps-4">{id ? "Edit" : "Add New"} Agent</h1>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <>
            <div className="container-fluid">
              <h2 className="form-title">General Information</h2>
              <div className="form-wrap">
                <div className="row pb-4 ed-bb">
                  <div className="col-md-6 mb-4">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Enter the first name"
                      className="form-control"
                      value={fields.firstName}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.firstName}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Enter the last name"
                      className="form-control"
                      value={fields.lastName}
                      onChange={handleChange}
                    />
                    <p className="error-txt">{errors.lastName}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label>Email Address</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter the email"
                      className="form-control"
                      value={fields.email}
                      onChange={(e) => {
                        handleChange(e);
                        setError("");
                      }}
                    />
                    <p className="error-txt">{errors.email || error}</p>
                  </div>
                  {!id && (
                    <div className="col-md-6 mb-4">
                      <label>Password</label>
                      <input
                        type="text"
                        name="password"
                        placeholder="Enter the password"
                        className="form-control"
                        value={fields.password}
                        onChange={handleChange}
                      />
                      <p className="error-txt">{errors.password}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="modal-footer">
                <button
                  className="btn ed-btn-default ed-btn-save-dt"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Save & Update"}
                </button>
              </div>
            </div>
          </>

        )}
      </div>
    </Fragment>
  );
}
export default Form;
